import { Expose, Type } from "class-transformer";
import { Entity } from "../entity";
import PDF from "../PDF/pdf";
import User from "../User/user";
import PMCActivityLog from "./pmcActivityLog";
import PMCInvoice from "./pmcInvoice";

export default class PMC extends Entity {
  @Expose() code!: string;
  @Expose() start_date!: string;
  @Expose() end_date!: string;
  @Expose() price!: number;
  @Expose() amount_paid!: number;
  @Expose() payment_status!: string;
  @Expose() notes!: string;
  @Expose() is_active = true;
  @Expose() pdf_documents_link!: string;

  @Type(() => User)
  @Expose()
  customer: User | null = new User();

  @Type(() => PDF)
  @Expose()
  pdfs: PDF[] = [];

  @Type(() => PMCActivityLog)
  @Expose()
  pmc_activity_logs: PMCActivityLog[] = [];

  @Type(() => PMCInvoice)
  @Expose()
  pmc_invoice: PMCInvoice | null = new PMCInvoice();
}
