import { Expose } from "class-transformer";
import { Entity } from "../entity";

export default class CourseOnlineDetail extends Entity {
  @Expose() start!: string | null;
  @Expose() end!: string | null;

  @Expose() meeting_id!: string;
  @Expose() meeting_link!: string;
  @Expose() meeting_homepage!: string;
  @Expose() meeting_pw!: string;
  @Expose() phone_number!: string;
}
