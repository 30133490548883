import "reflect-metadata";
import { Expose } from "class-transformer";
import moment from "moment";

type EntitySearch = {
  value: string;
  rules: string | null;
  criteria: string;
  criterias: {
    value: string;
    text: string;
    rules: string;
  }[];
};

class BaseEntity {}

class Entity extends BaseEntity {
  @Expose() id: number | null = null;
  @Expose() created_at: moment.Moment | null = null;
  @Expose() updated_at: moment.Moment | null = null;

  constructor(id: unknown | null = null) {
    super();
    this.id = id ? Number(id) : null;
  }
}

export { Entity, BaseEntity, EntitySearch };
