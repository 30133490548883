import { Expose, Type } from "class-transformer";
import CourseDocument from "../CourseDocument/courseDocument";
import { Entity } from "../entity";

export default class CourseText extends Entity {
  @Expose() information!: string;
  @Expose() closing_text!: string;
  @Expose() description_pdf: CourseDocument | null = new CourseDocument();
  @Expose() mail_reminder_information!: string;

  @Expose() pdf_documents_link!: string;
  @Expose() pdf_documents_after_course_link!: string;

  @Expose() documents: CourseDocument | null = new CourseDocument();
  @Expose() documents_after_course: CourseDocument | null =
    new CourseDocument();
}
