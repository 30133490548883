import { Expose, Type } from "class-transformer";
import { Entity } from "../entity";
import User from "../User/user";

export default class CourseLecture extends Entity {
  @Expose() guest_instructor!: string;

  @Type(() => User)
  @Expose()
  instructors: User[] = [];

  @Type(() => User)
  @Expose()
  instructor_assignments: User[] = [];
}
