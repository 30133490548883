import { Entity } from "../entity";
import { Expose, Type } from "class-transformer";
import Course from "../Course/course";

export default class Instructor extends Entity {
  @Type(() => Course)
  @Expose()
  applied_courses: Course[] = [];

  @Type(() => Course)
  @Expose()
  fixed_courses: Course[] = [];
}
