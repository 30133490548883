import { Expose } from "class-transformer";
import Certificate from "../Certificate/certificate";
import { Entity } from "../entity";

export default class CourseCertificate extends Entity {
  @Expose() start_date!: string;
  @Expose() end_date!: string;
  @Expose() is_released = false;
  @Expose() location!: string;
  @Expose() module_type!: string;
  @Expose() module_emphasis!: string;
  @Expose() notes!: string;
  @Expose() doctor_vnr!: string;
  @Expose() doctor_points!: number;
  @Expose() pdf_certificates_link!: string;
  @Expose() publish_date!: moment.Moment;

  @Expose() midwife: Certificate | null = null;
  @Expose() doctor: Certificate | null = null;
}
