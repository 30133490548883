import { Expose, Type } from "class-transformer";
import { Moment } from "moment";
import { Entity } from "../entity";
import User from "../User/user";

export default class BookingActivityLog extends Entity {
  @Expose() datetime!: Moment;
  @Expose() notes!: string;
  @Expose() price!: number;
  @Expose() type!: string;

  @Type(() => User)
  @Expose()
  created_by: User | null = new User();
}
