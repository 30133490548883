import { Expose } from "class-transformer";
import { Entity } from "../entity";

export default class PDF extends Entity {
  @Expose() identifier!: string;
  @Expose() datetime!: moment.Moment;
  @Expose() path!: string;
  @Expose() type!: string;
  @Expose() public_date!: moment.Moment;
}
