import { Expose } from "class-transformer";
import { Entity } from "../entity";
import User from "../User/user";

export default class Auth extends Entity {
  @Expose() token!: string;
  @Expose() refresh_token!: string;

  @Expose() data!: User;
}
