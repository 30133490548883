import { Expose } from "class-transformer";
import { Entity } from "../entity";
import moment from "moment";

export default class CourseDateDetail extends Entity {
  @Expose() start: string | null = null;
  @Expose() end: string | null = null;
  @Expose() date: string | null = null;

  constructor(date: moment.Moment) {
    super();

    this.date = date.format("YYYY-MM-DD");
  }
}
