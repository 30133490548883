import { Expose, Type } from "class-transformer";
import { Entity } from "../entity";
import CourseOnlineDetail from "./courseOnlineDetail";

export default class CourseOnline extends Entity {
  @Expose() meeting_link_description!: string | null;
  @Expose() meeting_homepage_description!: string | null;
  @Expose() phone_number_description!: string | null;
  @Expose() code_description!: string | null;

  @Type(() => CourseOnlineDetail)
  @Expose()
  details: CourseOnlineDetail[] = [];
}
