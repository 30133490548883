import { Expose } from "class-transformer";
import Booking from "../Booking/booking";
import { Entity } from "../entity";

export default class CourseCase extends Entity {
  @Expose() booking: Booking | null = null;
  @Expose() num!: number;
  @Expose() datetime!: moment.Moment;
  @Expose() medical_history!: string;
  @Expose() western_diagnosis!: string;
  @Expose() chinese_diagnosis!: string;
  @Expose() therapy_target!: string;
  @Expose() therapy_target_description!: string;
  @Expose() additional_procedures!: string;
  @Expose() result!: string;
  @Expose() education_status!: string;
  @Expose() is_complete = false;
}
