import { Entity } from "../entity";
import PMC from "../PMC/pmc";
import { Expose, Type } from "class-transformer";
import UserMasterdata from "./userMasterdata";
import { UserQualificationType } from "./enum";
import Instructor from "./instructor";
import InstructorDetail from "./instructorDetail";

export enum UserTypeEnum {
  CUSTOMER = "CUSTOMER",
  GUEST_INSTRUCTOR = "GUEST_INSTRUCTOR",
  INSTRUCTOR = "INSTRUCTOR",
  ADMIN = "ADMIN",
}

export default class User extends Entity {
  with_verification_mail = true;

  @Expose() apm_id!: number | null;
  @Expose() firstname!: string;
  @Expose() name!: string;
  @Expose() username!: string;
  @Expose() password!: string;
  @Expose() roles!: string[];
  @Expose() type!: UserTypeEnum;

  @Expose() is_active = true;
  @Expose() is_verified = false;
  @Expose() is_deleted = false;
  @Expose() newsletter = true;
  @Expose() booking_lock_pm = false;
  @Expose() booking_lock_congress = false;
  @Expose() qualification!: UserQualificationType;
  @Expose() instructor: Instructor | null = new Instructor();
  @Expose() notes!: string;

  @Expose() active_pro_medico_card!: PMC;

  @Type(() => PMC)
  @Expose()
  pro_medico_cards: PMC[] = [];

  @Type(() => UserMasterdata)
  @Expose()
  user_masterdata: UserMasterdata | null = new UserMasterdata();

  @Type(() => InstructorDetail)
  @Expose()
  instructor_detail: InstructorDetail | null = new InstructorDetail();
}
