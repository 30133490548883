import { Expose } from "class-transformer";
import { Entity } from "../entity";
import { UserCountryType } from "../User/enum";

export default class BookingAlternativeInvoiceAddress extends Entity {
  @Expose() is_active = false;
  @Expose() institution!: string;
  @Expose() name!: string;
  @Expose() firstname!: string;
  @Expose() street!: string;
  @Expose() street_number!: string;
  @Expose() postcode!: string;
  @Expose() city!: string;
  @Expose() country!: UserCountryType;
}
