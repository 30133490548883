import { Exclude, Expose, Type } from "class-transformer";
import moment from "moment";
import { BaseEntity } from "./entity";
import { UserTypeEnum } from "./User/user";

export type CollectionParameter = {
  courseCategory?: number | null;
  sort?: string;
  type?: string | (string | null)[] | null;
  page?: number;
  search?: string | (string | null)[] | null;
  searchCriteria?: string | (string | null)[] | null;
  limit?: number;
  public?: boolean;
  category?: string | null;
  onlyPMC?: string | (string | null)[];
  country?: string | (string | null)[] | null;
  userGroup?: UserTypeEnum | null;
  fromDate?: string | null;
  toDate?: string | null;
  sortingCountry?: string | null;
};

export default class Collection<T> {
  readonly itemsPerPage = 25;

  @Exclude()
  private type: BaseEntity;

  @Expose() limit = 0;
  @Expose() page = 1;
  @Expose() pages = 0;
  @Expose() results = 0;

  @Expose()
  @Type((options) => {
    return (options?.newObject as Collection<T>).type as () => void;
  })
  items: T[] = [];

  constructor(type: BaseEntity) {
    this.type = type;
  }

  get(key: number): T {
    return this.items[key];
  }

  set(key: number, value: T): void {
    this.items[key] = value;
  }
}
