import Auth from "@/entities/Auth/auth";
import axios, { AxiosResponse } from "axios";
import Service from ".";

export type AuthRequest = {
  path: string;
  user: {
    username: string;
    password: string;
  };
};

export type AuthResponse = {
  token: string;
};

export default class AuthService {
  static service = new Service(Auth);

  public static login(auth: AuthRequest): Promise<AxiosResponse<Auth>> {
    return this.service.post(auth.path, {
      username: auth.user.username,
      password: auth.user.password,
    });
  }

  public static refresh(refreshToken: string): Promise<AxiosResponse<Auth>> {
    return axios.create().post("/api/token/refresh", {
      refresh_token: refreshToken,
    });
  }
}
