import { Exclude, Expose, Transform, Type } from "class-transformer";
import { Entity } from "../entity";
import Course from "../Course/course";
import User from "../User/user";
import PDF from "../PDF/pdf";
import moment from "moment";
import CoursePriceStructureDetail from "../Course/coursePriceStructureDetail";
import BookingActivityLog from "./bookingActivityLog";
import Voucher from "../Voucher/voucher";
import VoucherCode from "../Voucher/voucherCode";
import BookingAlternativeInvoiceAddress from "./bookingAlternativeInvoiceAddress";
import BookingParticipationConfirmation from "./bookingParticipationConfirmation";
import CourseCase from "../CourseCase/courseCase";
import ExamResult from "../ExamResult/examResult";
import BookingInvoice from "./bookingInvoice";
import BookingCertificate from "./bookingCertificate";

export default class Booking extends Entity {
  @Expose() code!: string;
  @Expose() status!: string;
  @Expose() payment_status!: string;
  @Expose() price!: number;
  @Expose() amount_paid!: number;
  @Expose() is_paid!: boolean;
  @Expose() displayPrice!: number;
  @Expose() category!: string;
  @Expose() notes!: string;
  @Expose() voucher_code!: VoucherCode;

  @Expose()
  datetime!: moment.Moment;

  @Type(() => CoursePriceStructureDetail)
  @Expose()
  price_detail: CoursePriceStructureDetail | null =
    new CoursePriceStructureDetail();

  @Type(() => BookingAlternativeInvoiceAddress)
  @Expose()
  alternative_invoice_address: BookingAlternativeInvoiceAddress | null =
    new BookingAlternativeInvoiceAddress();

  @Type(() => User)
  @Expose()
  customer: User | null = new User();

  @Type(() => Course)
  @Expose()
  course: Course | null = new Course();

  @Type(() => BookingInvoice)
  @Expose()
  booking_invoice: BookingInvoice | null = new BookingInvoice();

  @Expose() is_approved_for_online_exam = false;
  @Expose() invoice_sent_date!: string;
  @Expose() voucher!: string;
  @Expose() voucherObject!: Voucher;
  @Expose() access_code!: string;
  @Expose() transfer_value!: number;
  @Expose() storno_value!: number;
  @Expose() fee!: number;
  @Expose() surcharge!: number;
  @Expose() update_type!: string;
  @Expose() handout_released = false;

  @Expose() pdf_documents_link!: string;
  @Expose() pdf_course_cases_link!: string;

  @Type(() => BookingActivityLog)
  @Expose()
  booking_activity_logs: BookingActivityLog[] = [];

  @Type(() => CourseCase)
  @Expose()
  course_cases: CourseCase[] = [];

  @Expose() course_cases_is_complete = false;

  @Type(() => PDF)
  @Expose()
  pdfs: PDF[] = [];

  @Expose() transfer_from!: Booking;
  @Expose() transfer_to!: Booking;

  @Expose() participation_confirmation!: BookingParticipationConfirmation;
  @Expose() exam_result!: ExamResult;
  @Expose() exam_result_theoretical!: ExamResult;

  @Transform(({ value, key, obj }) => obj.is_approved_for_online_exam ?? true, { toClassOnly: true })
  @Expose()
  online_exam_approved = true;

  @Type(() => BookingCertificate)
  @Expose()
  certificate: BookingCertificate | null = new BookingCertificate();
}
