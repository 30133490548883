import { Expose } from "class-transformer";
import { Entity } from "../entity";

export default class CourseOnSiteDetail extends Entity {
  @Expose() code!: string;
  @Expose() price!: number;
  @Expose() price_pmc!: number;
  @Expose() has_price_pmc = false;
  @Expose() max_participants!: number;
  @Expose() is_active!: boolean;
}
