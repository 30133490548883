import { Expose } from "class-transformer";
import { Entity } from "../entity";
import CourseOnSiteDetail from "./courseOnSiteDetail";

export default class CourseOnSite extends Entity {
  @Expose() location_room!: string;
  @Expose() contact_person!: string;
  @Expose() contact_person_tel!: string;
  @Expose() contact_person_mail!: string;

  @Expose() internal: CourseOnSiteDetail = new CourseOnSiteDetail();
  @Expose() external: CourseOnSiteDetail = new CourseOnSiteDetail();

  public static getSchema(): Record<string, unknown> {
    return {
      Kursnummer: { type: String, required: true, prop: "internal_id" },
      Name: { type: String, required: true, prop: "name" },
      Kursvorlage: {
        type: String,
        required: true,
        prop: "course_template",
      },
      Typ: { type: String, required: true, prop: "type" },
      Location: { type: String, required: true, prop: "location" },
      Rechnungsland: { type: String, required: true, prop: "invoice_country" },
      Zahlungsziel: { type: Date, required: true, prop: "payment_date" },
      Rechnungsversand: { type: Date, required: true, prop: "invoice_date" },
      "Kurstag 1": { type: String, required: true, prop: "day1" },
      "Kurstag 2": { type: String, required: false, prop: "day2" },
      "Veranstaltungsort - Raum": {
        type: String,
        required: true,
        prop: "location_room",
      },
      Ansprechpartner: { type: String, required: true, prop: "contact_person" },
      "Ansprechpartner - Tel.": {
        type: String,
        required: false,
        prop: "contact_person_tel",
      },
      "Ansprechpartner - Mail": {
        type: String,
        required: false,
        prop: "contact_person_mail",
      },
      "Intern - Code": { type: String, required: false, prop: "internal_code" },
      "Intern - Teilnehmer": {
        type: Number,
        required: false,
        prop: "internal_max_participants",
      },
      "Intern - Preis": {
        type: Number,
        required: false,
        prop: "internal_price",
      },
      "Intern - Preis (PMC)": {
        type: Number,
        required: false,
        prop: "internal_price_pmc",
      },
      "Extern - Code": { type: String, required: false, prop: "external_code" },
      "Extern - Teilnehmer": {
        type: Number,
        required: false,
        prop: "external_max_participants",
      },
      "Extern - Preis": {
        type: Number,
        required: false,
        prop: "external_price",
      },
      "Extern - Preis (PMC)": {
        type: Number,
        required: false,
        prop: "external_price_pmc",
      },
    };
  }
}
