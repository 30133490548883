import { Expose } from "class-transformer";
import { Entity } from "../entity";
import CoursePriceStructureDetail from "./coursePriceStructureDetail";

export default class CoursePriceStructure extends Entity {
  @Expose() standard: CoursePriceStructureDetail =
    new CoursePriceStructureDetail();
  @Expose() pmc: CoursePriceStructureDetail | null =
    new CoursePriceStructureDetail();
  @Expose() special: CoursePriceStructureDetail | null =
    new CoursePriceStructureDetail();
}
