import { Expose } from "class-transformer";
import { Entity } from "../entity";

export default class Location extends Entity {
  @Expose() code!: string;
  @Expose() type!: string;

  @Expose() name!: string;
  @Expose() street!: string;
  @Expose() street_number!: string;
  @Expose() attachment!: string;
  @Expose() postcode!: string;
  @Expose() city!: string;
  @Expose() country!: string;
  @Expose() phone!: string;
  @Expose() homepage!: string;
  @Expose() addendum!: string;
  @Expose() path!: string;
}
