import { Entity } from "../entity";
import { Expose } from "class-transformer";
import {
  UserCountryTelType,
  UserCountryType,
  UserProfessionType,
  UserSalutationType,
  UserTitleType,
} from "./enum";
import moment from "moment";
import ExamResult from "../ExamResult/examResult";

export default class UserMasterdata extends Entity {
  @Expose() birthday!: moment.Moment;
  @Expose() city!: string;
  @Expose() country!: UserCountryType;
  @Expose() phone_business!: string | null;
  @Expose() phone_business_country!: UserCountryTelType | null;
  @Expose() phone_private!: string | null;
  @Expose() phone_private_country!: UserCountryTelType | null;
  @Expose() postcode!: string;
  @Expose() profession!: UserProfessionType;
  @Expose() salutation!: UserSalutationType;
  @Expose() street!: string;
  @Expose() street_number!: string;
  @Expose() title!: UserTitleType;
  @Expose() has_birthname = false;
  @Expose() birthname!: string | null;
  @Expose() exam_result!: ExamResult;
  @Expose() exam_result_theoretical!: ExamResult;
}
