import { Expose } from "class-transformer";
import { Moment } from "moment";
import { Entity } from "../entity";

export default class BookingCertificate extends Entity {
  @Expose() datetime!: Moment;
  @Expose() sent_date!: Moment;
  @Expose() released = false;
  @Expose() restricted = false;
  @Expose() link!: string;
}
