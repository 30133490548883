import { BaseEntity, Entity } from "@/entities/entity";
import Collection from "@/entities/collection";
import EntityEnum from "@/entities/entityEnum";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import {
  ClassConstructor,
  plainToClassFromExist,
  plainToInstance,
} from "class-transformer";

export default class Service {
  entity: BaseEntity;

  constructor(entity: BaseEntity) {
    this.entity = entity;
  }

  private static transformPlainCollection(
    collection: Collection<Entity>,
    data: unknown
  ): BaseEntity {
    data = plainToClassFromExist(collection, data, {
      excludeExtraneousValues: true,
    });

    return data as Collection<Entity>;
  }

  public static transformPlain(entity: BaseEntity, data: unknown): BaseEntity {
    data = plainToInstance(entity as ClassConstructor<BaseEntity>, data, {
      excludeExtraneousValues: true,
    });

    return data as BaseEntity;
  }

  public async blob(path: string, data?: unknown): Promise<AxiosResponse> {
    return axios.post(path, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "blob",
    });
  }

  public async enum(
    entity: ClassConstructor<EntityEnum>,
    path: string,
    options?: AxiosRequestConfig<unknown>
  ): Promise<AxiosResponse> {
    return axios.get(path, options).then((res) => {
      res.data = Service.transformPlain(entity, res.data);

      return res;
    });
  }

  public static findInEnumByKey(entity: EntityEnum[], key: string): string {
    return (
      entity.find((item: EntityEnum) => item && item?.key == key)?.value || ""
    );
  }

  public async collection(
    path: string,
    options?: AxiosRequestConfig<unknown>,
    entity: BaseEntity | null = null
  ): Promise<AxiosResponse> {
    return axios.get(path, options).then((res) => {
      res.data = Service.transformPlainCollection(
        new Collection<Entity>(entity ?? this.entity),
        res.data
      );

      return res;
    });
  }

  public async plain(
    path: string,
    options?: AxiosRequestConfig<unknown>
  ): Promise<AxiosResponse> {
    return axios.get(path, options);
  }

  public async get(
    path: string,
    options?: AxiosRequestConfig<unknown>,
    entity: BaseEntity | null = null
  ): Promise<AxiosResponse> {
    return axios.get(path, options).then((res) => {
      res.data = Service.transformPlain(entity ?? this.entity, res.data);

      return res;
    });
  }

  public async post(
    path: string,
    data?: unknown,
    options?: AxiosRequestConfig<unknown>,
    entity: BaseEntity | null = null
  ): Promise<AxiosResponse> {
    return axios.post(path, data, options).then((res) => {
      res.data = Service.transformPlain(entity ?? this.entity, res.data);

      return res;
    });
  }

  public async patch(
    path: string,
    data?: unknown,
    options?: AxiosRequestConfig<unknown>
  ): Promise<AxiosResponse> {
    return axios.patch(path, data, options).then((res) => {
      res.data = Service.transformPlain(this.entity, res.data);

      return res;
    });
  }

  public async delete(
    path: string,
    options?: AxiosRequestConfig<unknown>
  ): Promise<AxiosResponse> {
    return axios.delete(path, options).then((res) => {
      res.data = Service.transformPlain(this.entity, res.data);

      return res;
    });
  }
}
