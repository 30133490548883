import { Expose } from "class-transformer";
import { Moment } from "moment";
import { Entity } from "../entity";

export default class PMCInvoice extends Entity {
  @Expose() date!: Moment;
  @Expose() dunning_date!: Moment;
  @Expose() dunning_deadline_date!: Moment;
  @Expose() payment_date!: Moment;
}
