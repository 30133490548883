import { Expose } from "class-transformer";
import { Entity } from "../entity";

export enum InvoiceType {
  IMMEDIATELY = "IMMEDIATELY",
  DATE = "DATE",
}

export default class CourseInvoice extends Entity {
  @Expose() dunning_date!: string;
  @Expose() dunning_deadline_date!: string;
  @Expose() payment_date!: string;
  @Expose() date!: string;
  @Expose() type: InvoiceType = InvoiceType.DATE;
}
