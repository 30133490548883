import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import AuthService, { AuthRequest } from "@/services/auth.service";
import { AxiosResponse } from "axios";
import store from "@/store";
import Vue from "vue";

type AuthMutation = {
  loggedIn: boolean;
  data: {
    id: number;
    firstname: string;
    name: string;
    user: string;
    roles: string[];
  } | null;
  token: {
    jwt: string;
    refresh: string;
  } | null;
};

@Module({
  name: "auth",
  namespaced: true,
  store,
  dynamic: true,
})
class AuthModule extends VuexModule {
  public static readonly localStorageName = "promedico-ts";

  auth: AuthMutation | null = this.getStorage();

  private getStorage(): AuthMutation | null {
    try {
      const storage = localStorage.getItem(AuthModule.localStorageName);
      if (!storage) {
        return null;
      }
      return JSON.parse(storage) as AuthMutation;
    }
    catch (err) {
      return null;
    }
  }

  get id(): number | null {
    if (Vue.$cookies.isKey("IMPERSONATE")) {
      return Vue.$cookies.get("IMPERSONATE").id;
    }

    if (this.auth?.data?.id) {
      return this.auth.data.id;
    }

    return null;
  }

  get isLoggedIn(): boolean {
    return this.auth?.loggedIn || false;
  }

  get getAuth(): AuthMutation | null {
    return this.auth;
  }

  @Action({ rawError: true })
  public login(auth: AuthRequest): Promise<AxiosResponse> {
    return AuthService.login(auth).then(
      (res) => {
        this.context.commit("mutation", {
          loggedIn: true,
          data: {
            id: res.data.data.id,
            firstname: res.data.data.firstname,
            name: res.data.data.name,
            user: res.data.data.username,
            roles: res.data.data.roles,
          },
          token: {
            jwt: res.data.token,
            refresh: res.data.refresh_token,
          },
        } as AuthMutation);

        return Promise.resolve(res);
      },
      (error) => {
        this.context.commit("mutation", {
          loggedIn: false,
          data: null,
          token: null,
        } as AuthMutation);

        return Promise.reject(error);
      }
    );
  }

  @Action({ rawError: true })
  public refresh(): Promise<AxiosResponse> {
    return AuthService.refresh(this.auth?.token?.refresh || "")
      .then(
        (res) => {
          this.context.commit("mutation", {
            loggedIn: true,
            data: {
              id: res.data.data.id,
              firstname: res.data.data.firstname,
              name: res.data.data.name,
              user: res.data.data.username,
              roles: res.data.data.roles,
            },
            token: {
              jwt: res.data.token,
              refresh: res.data.refresh_token,
            },
          } as AuthMutation);

          return Promise.resolve(res);
        },
        (error) => {
          this.context.commit("mutation", {
            loggedIn: false,
            data: null,
            token: null,
          } as AuthMutation);

          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return error;
      });
  }

  @Action
  public logout(): void {
    this.context.commit("mutation", {
      loggedIn: false,
      data: null,
      token: null,
    } as AuthMutation);
  }

  @Mutation
  public mutation(authMutation: AuthMutation): void {
    this.auth = authMutation;

    localStorage.setItem(
      AuthModule.localStorageName,
      JSON.stringify(this.auth)
    );

    if (this.auth.token?.jwt) {
      Vue.$cookies.set("BEARER", this.auth.token?.jwt);
    } else {
      Vue.$cookies.remove("BEARER");
    }
  }
}

export default getModule(AuthModule);
